@use '@angular/material' as mat;
@use 'assets/theme/theme.scss' as theme;
@use 'assets/styles/scrollbar.scss' as scrollbar;

@include mat.core();
@include mat.all-component-themes(theme.$custom-theme);
@include scrollbar.scrollbar;

:root{
  --yellow: #D4C16B;
  --black: #000000d3;
  --background: rgb(48, 48, 48);
  --hoverBackground: rgba(37, 37, 37, 0.925);
  --white: #ffffffd7;
  --gray: #464646d5;
  --green: #7AFF6D;
  --red: #FF5858;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

ngx-spinner {
  z-index: 9999 !important;
}

.mat-form-filter-input {
  @include mat.form-field-density(-2);
  min-width: 0 !important;
}

.mat-drawer-backdrop.mat-drawer-shown {
  visibility: visible !important;
  background-color: rgb(0 0 0 / 60%) !important;
}

.search-input {
  @include mat.form-field-density(-3);
  width: 30%;
  box-sizing: border-box;
  margin-right: 10px;
}

mat-datepicker-toggle button svg {
  height: 20px !important;
  width: 20px !important;
}

//Colors for snackbar
.mat-mdc-snack-bar-container {
  &.red-snackbar {
    --mdc-snackbar-container-color: var(--yellow);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #630000;
  }
}

.mat-mdc-snack-bar-container {
  &.green-snackbar {
    --mdc-snackbar-container-color: var(--yellow);
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #064900;
  }
}

